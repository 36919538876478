import { Button } from "antd";
import AOS from "aos";
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";

AOS.init({});

const MainMenu = () => {
  return (
    <div className="header-menus">
      <Link to={{ pathname: "https://docs.commodo.one/" }} target="_blank" rel="noopener noreferrer">
        Docs
      </Link>
      <Link
        to={{ pathname: "https://forum.comdex.one/c/commodo-discussion/" }}
        target="_blank" rel="noopener noreferrer"
      >
        Forum
      </Link>
      <Link to={{ pathname: "https://t.me/commodo_finance" }} target="_blank" rel="noopener noreferrer">
        Community
      </Link>
    </div>
  );
};

const Navbar = () => {
  return (
    <div className="home-header-wrapper">
      <div className="home-header" data-aos="fade" data-aos-delay="200">
        <div className="logo-wrapper">
          <div className="logo">
            <Link to="/">
              <svg width="76" height="41" viewBox="0 0 76 41" fill="none">
                <path
                  d="M11.9219 40.5421C10.5783 39.4715 11.5884 38.7522 12.1418 38.0771C15.863 33.535 19.6564 29.0498 25.7301 27.4884C27.4171 27.0547 27.2801 26.4154 26.2284 25.2995C23.4171 22.3197 19.3298 22.1712 15.4464 25.0234C10.2108 28.8681 6.59828 34.0778 3.13607 39.4234C2.61908 40.2214 2.21059 41.7268 0.697196 40.4876C0.15329 40.0421 -0.301235 39.7049 0.250787 38.8516C4.53397 32.2393 8.80811 25.6222 15.9667 21.616C20.5166 19.0699 24.5861 19.7258 28.1559 23.5193C31.6993 27.2817 35.213 31.0706 38.735 34.8525C41.5427 37.8663 44.0684 38.2617 47.5322 35.9711C53.315 32.1445 57.1687 26.5576 61.0123 20.9925C65.3444 14.7199 69.0389 8.06749 72.7396 1.42059C73.2522 0.500239 73.5681 -0.585491 75.1205 0.372805C76.3023 1.10257 75.9498 1.71453 75.5292 2.302L73.4604 5.89547C68.5766 14.6207 63.5156 23.2388 57.0273 30.9372C54.596 33.8217 51.7546 36.302 48.6778 38.4821C47.1408 39.5709 45.3728 40.2596 43.4615 40.0563C40.8462 40.3296 38.9828 39.0491 37.3579 37.3316C35.9014 35.7921 34.5814 34.1233 33.0884 32.6201C30.3512 29.8643 27.256 29.4323 23.7897 31.1423C19.7833 33.1184 16.9458 36.3521 14.2668 39.7696C13.8511 40.2995 13.5396 41 12.9217 41C12.6557 40.9993 12.3334 40.87 11.9219 40.5421ZM38.0966 30.2655C44.4583 27.4391 48.3136 22.287 51.9403 16.9668C54.4133 13.3384 56.6379 9.54076 58.9216 5.78772C59.407 4.98914 59.7603 4.28047 60.8328 4.95855C61.7276 5.52322 62.0989 6.0532 61.4324 7.17914C57.0809 14.5289 52.7897 21.9175 46.5533 27.918C45.2619 29.1394 43.8602 30.2401 42.3661 31.2062C41.3551 31.8717 40.7658 32.2328 40.2709 32.2328C39.6269 32.2328 39.1425 31.6205 38.0966 30.2655V30.2655ZM31.8612 23.4407C37.3083 21.0761 40.5595 16.5857 43.6881 11.9701C44.3529 10.9905 45.0408 10.0268 45.6845 9.03386C46.2321 8.19124 46.8771 8.52413 47.4224 8.92809C47.905 9.28589 48.6446 9.57503 48.0102 10.5105C44.6036 15.5351 41.3588 20.7025 36.235 24.2494C35.4804 24.7711 34.6651 25.283 33.8887 25.283C33.1464 25.2826 32.4405 24.8149 31.8612 23.441V23.4407Z"
                  fill="url(#paint0_linear_737_2398)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_737_2398"
                    x1="37.963"
                    y1="0"
                    x2="37.963"
                    y2="41"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#E2F7E5" />
                    <stop offset="1" stopColor="#52B788" />
                  </linearGradient>
                </defs>
              </svg>
              COMMODO
            </Link>
          </div>
        </div>
        <div className="header-right">
          <MainMenu />
          <div className="launch-btn">
            <Link to={{ pathname: "https://app.commodo.one" }} target="_blank" rel="noopener noreferrer">
              <Button className="btn-filled">Launch App</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
