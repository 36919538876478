import React, { Component } from 'react';
import AOS from 'aos';
import './Footer.scss';

AOS.init({});

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="home-footer">
          Footer
        </div>
      </React.Fragment>
    )
  }
}

export default Footer