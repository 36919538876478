// Layout Types
import { homeLayout } from "./layouts";

// Route Views
import Homepage from "./components/Homepage/Homepage";

export default [
  {
    path: "/",
    exact: true,
    layout: homeLayout,
    component: Homepage
  }
];