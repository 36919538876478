import { Button, Divider, Tooltip } from 'antd';
import AOS from 'aos';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import 'swiper/swiper.scss';
// Import images 
import BannerVideo from '../../assets/banner-video.mp4';
import BannerImage from '../../assets/images/banner-bg.jpg';
import Howwork1 from '../../assets/images/howwork-1.svg';
import Howwork2 from '../../assets/images/howwork-2.svg';
import Hover11 from '../../assets/images/howwork-hover1-1.svg';
import Hover12 from '../../assets/images/howwork-hover1-2.svg';
import Hover13 from '../../assets/images/howwork-hover1-3.svg';
import Hover21 from '../../assets/images/howwork-hover2-1.svg';
import Hover22 from '../../assets/images/howwork-hover2-2.svg';
import Hover23 from '../../assets/images/howwork-hover2-3.svg';
import { SvgIcon } from '../common';
import './Homepage.scss';


function SlideOne() {
    return <div style={{ height: '100vh', width: '100vw' }}>
        <div className='slide-1'>
            <h1 data-aos="fade-up" data-aos-duration="600">Interchain Liquidity Protocol</h1>
            <p className='sub-title'>for the cosmos ecosystem</p>
            <p data-aos="fade-up" data-aos-duration="800">Built on
                <Link to={{ pathname: 'https://comdex.one' }} target="_blank" rel="noopener noreferrer"><SvgIcon name="logo-colored" viewbox="0 0 112.095 24.926" /></Link></p>
            <div data-aos="fade-up" data-aos-duration="900">
                <Link to={{ pathname: 'https://app.commodo.one' }} target="_blank" rel="noopener noreferrer"><Button size='large' ghost>Launch App</Button></Link>
            </div>
        </div>
    </div>
}

function SlideTwo() {
    return <div style={{ height: '100vh', width: '100vw' }}>
        <div className='slide-2'>
            <h1 data-aos="fade-up" data-aos-duration="600">Why Commodo ?</h1>
            <ul>
                <li data-aos="zoom-in" data-aos-duration="600" className='circle-items'>
                    <div className='circle-items-inner'>
                        <div className='circle-icon'><SvgIcon name='percent-icon' viewbox="0 0 49 49" /></div>
                        <h3>Sustainable yields</h3>
                        <p>Earn yield from liquidity provision and arbitrage opportunities</p>
                    </div>
                </li>
                <li data-aos="zoom-in" data-aos-duration="700" className='circle-items'>
                    <div className='circle-items-inner'>
                        <div className='circle-icon'><SvgIcon name='secure-mechanism' viewbox="0 0 62.001 62.003" /></div>
                        <h3>Secure Mechanism</h3>
                        <p>Secure money markets through cPools and Transit assets architecture</p>
                    </div>
                </li>
                <li data-aos="zoom-in" data-aos-duration="800" className='circle-items'>
                    <div className='circle-items-inner'>
                        <div className='circle-icon'><SvgIcon name='permissionless' viewbox="0 0 66 66" /></div>
                        <h3>Permissionless</h3>
                        <p>Deposit and borrow from a wide range of IBC-enabled assets on chain</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
}

function SlideThree() {
    const TooltipContent1 = (
        <div className="token-details token-details-small">
            <div className="tokencard-col">
                <div className="tokencard">
                    <div className="tokencard-icon">
                        <SvgIcon name="tokena" viewbox='0 0 36 36' />
                    </div>
                    <p>DEPOSIT TOKEN A</p>
                </div>
                <SvgIcon
                    className="token-down-arrow"
                    name="tokenarrow-down"
                    viewbox="0 0 9.774 45.02"
                />
                <div className="tokencard with-shadow">
                    <div className="tokencard-icon">
                        <SvgIcon name="tokenc" viewbox='0 0 36 36' />
                    </div>
                    <p>
                        BORROW TRANSIT ASSET 1/2
                    </p>
                </div>
                <label>#</label>
            </div>
            <div className='bottom-card-tooltip'>
                Depositing Token A in cPool 1 to borrow Transit asset 1 or Transit asset 2
            </div>
        </div>
    );

    const TooltipContent2 = (
        <div className="token-details">
            <div className="tokencard-col">
                <div className="tokencard">
                    <div className="tokencard-icon">
                        <SvgIcon name="tokena" viewbox='0 0 36 36' />
                    </div>
                    <p>DEPOSIT TOKEN A</p>
                </div>
                <SvgIcon
                    className="token-down-arrow"
                    name="tokenarrow-down"
                    viewbox="0 0 9.774 45.02"
                />
                <div className="tokencard with-shadow">
                    <div className="tokencard-icon">
                        <SvgIcon name="tokenc" viewbox='0 0 36 36' />
                    </div>
                    <p>
                        BORROW TRANSIT ASSET 1/2
                    </p>
                </div>
                <label>#1</label>
            </div>
            <div className="middle-arrow">
                <SvgIcon name="token-arrow" viewbox="0 0 159 80.387" />
            </div>
            <div className="tokencard-col">
                <div className="tokencard with-shadow">
                    <div className="tokencard-icon">
                        <SvgIcon name="tokenc" viewbox='0 0 36 36' />
                    </div>
                    <p>
                        DEPOSIT TRANSIT ASSET 1/2
                    </p>
                </div>
                <SvgIcon
                    className="token-down-arrow"
                    name="tokenarrow-down"
                    viewbox="0 0 9.774 45.02"
                />
                <div className="tokencard">
                    <div className="tokencard-icon">
                        <SvgIcon name="tokena" viewbox='0 0 36 36' />
                    </div>
                    <p>BORROW TOKEN B</p>
                </div>
                <label>#2</label>
            </div>
            <div className='bottom-card-tooltip'>
                Depositing Token A in cPool 1 to borrow Token B from cPool 2 using the Transit asset 1/2
            </div>
        </div>
    );
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);
    return <div style={{ height: '100vh', width: '100vw' }}>
        <div className='slide-3'>
            <h1 data-aos="fade-up" data-aos-duration="600">How it works</h1>
            <Divider />
            <div className='how-work-wrapper'>
                <div className='upper-lines'>
                    {hovered ? <SvgIcon name='line-upper-hover' viewbox='0 0 823.666 76.097' /> : <SvgIcon name='line-upper' viewbox='0 0 823.666 76.097' />}
                </div>
                <div className={hovered ? 'hover-token-b howwork-row' : 'howwork-row'}>
                    <div className='howwork-col1'>
                        <div className='how-inner-card'>
                            <div className='card-upper'>
                                <Tooltip
                                    title="Depositing Transit asset 1 in cPool 1 to borrow Token A or Transit asset 2"
                                    overlayClassName="bottom-tooltip"
                                    showArrow={false}
                                >
                                    <div className='col1'></div>
                                </Tooltip>
                                <img src={Hover11} alt='Hover' className='hover-images hover-image1-1' />
                                <Tooltip
                                    title="Depositing Transit asset 2 in cPool 1 to borrow Token A or Transit asset 1"
                                    overlayClassName="bottom-tooltip"
                                    showArrow={false}
                                >
                                    <div className='col2'></div>
                                </Tooltip>
                                <img src={Hover12} alt='Hover' className='hover-images hover-image1-2' />
                            </div>
                            <div className='card-bottom'>
                                <Tooltip
                                    className="commodo-tooltip tooltip-icon"
                                    placement="right"
                                    title={TooltipContent1}
                                    overlayClassName="token-overlay small-overlay"
                                    showArrow={false}
                                >
                                    <div className='col3'></div>
                                </Tooltip>
                                <img src={Hover13} alt='Hover' className='hover-images hover-image1-3' />
                            </div>
                        </div>
                        <img className='main-img' src={Howwork1} alt='How work' />
                    </div>
                    <div className='howwork-col2'>
                        <div className='how-inner-card'>
                            <div className='card-upper'>
                                <Tooltip
                                    title="Depositing Transit asset 1 in cPool 2 to borrow Token B or Transit asset 2"
                                    overlayClassName="bottom-tooltip"
                                    showArrow={false}
                                >
                                    <div className='col1'></div>
                                </Tooltip>
                                <img src={Hover21} alt='Hover' className='hover-images hover-image1-1' />
                                <Tooltip
                                    title="Depositing Transit asset 2 in cPool 2 to borrow Token B or Transit asset 1"
                                    overlayClassName="bottom-tooltip"
                                    showArrow={false}
                                >
                                    <div className='col2'></div>
                                </Tooltip>
                                <img src={Hover22} alt='Hover' className='hover-images hover-image1-2' />
                            </div>
                            <div className='card-bottom' onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                                <Tooltip
                                    className="commodo-tooltip tooltip-icon"
                                    placement="right"
                                    title={TooltipContent2}
                                    overlayClassName="token-overlay"
                                    showArrow={false}
                                >
                                    <div className='col3'></div>
                                </Tooltip>
                                <img src={Hover23} alt='Hover' className='hover-images hover-image1-3' />
                            </div>
                        </div>
                        <img className='main-img' src={Howwork2} alt='How work' />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function SlideFour() {
    return <div style={{ height: '100vh', width: '100vw' }}>
        <div className='slide-4'>
            <h1 data-aos="fade-up" data-aos-duration="600">Explore the community</h1>
            <p data-aos="fade-up" data-aos-duration="800">Take part in the discussion and interact with other like-minded community members through the below channels.
            </p>
            <ul className='social-links'>
                <li data-aos="zoom-in" data-aos-duration="400" className='circle-items'>
                    <Link to={{ pathname: 'https://discord.gg/comdexofficial' }} target="_blank" rel="noopener noreferrer">
                        <div className='circle-items-inner'>
                            <SvgIcon name='discord' viewbox='0 0 65.002 49.503' />
                        </div>
                    </Link>
                </li>
                <li data-aos="zoom-in" data-aos-duration="600" className='circle-items'>
                    <Link to={{ pathname: 'https://twitter.com/Commodo_Finance' }} target="_blank" rel="noopener noreferrer">
                        <div className='circle-items-inner'>
                            <SvgIcon name='twitter' viewbox='0 0 55.819 46.915' />
                        </div>
                    </Link>
                </li>
                <li data-aos="zoom-in" data-aos-duration="800" className='circle-items'>
                    <Link to={{ pathname: 'https://t.me/commodo_finance' }} target="_blank" rel="noopener noreferrer">
                        <div className='circle-items-inner'>
                            <SvgIcon name='telegram' viewbox='0 0 54.209 46.163' />
                        </div>
                    </Link>
                </li>
                <li data-aos="zoom-in" data-aos-duration="700" className='circle-items'>
                    <Link to={{ pathname: 'https://forum.comdex.one/c/commodo-discussion/' }} target="_blank" rel="noopener noreferrer">
                        <div className='circle-items-inner'>
                            <SvgIcon name='chat' viewbox='0 0 30 30' />
                        </div>
                    </Link>
                </li>
                <li data-aos="zoom-in" data-aos-duration="700" className='circle-items'>
                    <Link to={{ pathname: 'https://medium.com/@Commodo_Finance' }} target="_blank" rel="noopener noreferrer">
                        <div className='circle-items-inner'>
                            <SvgIcon name='medium' viewbox='0 0 58.333 48' />
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
    </div>
}

AOS.init();


function SliderPrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <SvgIcon name='prev-arrow' viewbox='0 0 48.856 48.856' />
        </div>
    );
}

function SliderNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <SvgIcon name='next-arrow' viewbox='0 0 48.856 48.856' />
        </div>
    );
}

const Homepage = () => {
    const settings = {
        dots: true,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        touchMove: true,
        slidesToScroll: 1,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />
    };
    const sliderRef = createRef();
    const scroll = useCallback(
        y => {
            if (y > 0) {
                return sliderRef?.current?.slickNext(); /// ? <- using description below 
            } else {
                return sliderRef?.current?.slickPrev();
            }
        },
        [sliderRef]
    );
    useEffect(() => {
        window.addEventListener("wheel", e => {
            scroll(e.deltaY);
        });
    }, [scroll]);
    return (
        <React.Fragment>
            <div className="home-wrapper">
                <video
                    className='banner-video'
                    playsInline
                    loop
                    autoPlay
                    muted
                    controls={false}
                    poster={BannerImage}
                    alt="All the devices"
                    src={BannerVideo}
                />
                <div className="home-slider" style={{ height: '100vh', width: '100vw' }}>
                    <Slider ref={sliderRef} className='home-slider' {...settings}>
                        <div>
                            <SlideOne />
                        </div>
                        <div>
                            <SlideTwo />
                        </div>
                        <div>
                            <SlideThree />
                        </div>
                        <div>
                            <SlideFour />
                        </div>
                    </Slider>
                    {/* <Swiper
                            slidesPerView={1}
                            mousewheel={true}
                            navigation={true}
                        >
                            <SwiperSlide>
                                <SlideOne />
                            </SwiperSlide>
                            <SwiperSlide>
                                <SlideTwo />
                            </SwiperSlide>
                            <SwiperSlide>
                                <SlideThree />
                            </SwiperSlide>
                        </Swiper> */}
                </div>
                {/* <MediaQuery minWidth={991}>
                    <div className="home-slider" style={{ height: '100vh', width: '100vw' }}>
                        <HorizontalScroll reverseScroll>
                            <SlideOne />
                            <SlideTwo />
                            <SlideThree />
                        </HorizontalScroll>
                    </div>
                </MediaQuery> */}

            </div>
        </React.Fragment>
    )
}

export default Homepage
